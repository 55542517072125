export default class Viewhandler {
    constructor(selector) {
        this.view = $(selector);
        this.page = null;
        this.pages = [];
        this.slugs = [];
        this.history = [];
        this.data = {};
    }

    newAsync(max, done) {
        var na = {
            count: 0,
            max: max,
            done: done,
        };

        return function () {
            na.count++;
            if (na.count >= na.max) {
                done();
            }
        };
    }

    addPage(slug, selector, open, close) {
        // console.log("Added page", slug);
        var view = $(selector);
        var page = {
            view: view,
            events: {
                open: [],
                close: [],
            },
        };
        if (typeof open == "function") {
            page.events.open.push(open);
        }
        if (typeof close == "function") {
            page.events.close.push(close);
        }
        this.slugs.push(slug);
        this.pages.push(page);
    }

    addPageEvent(page, event, fn) {
        this.pages[page].events[event].push(fn);
    }

    triggerPageEvents(event, page, done) {
        if (typeof page !== "number") {
            page = this.page;
        }

        var events = this.pages[page].events[event];

        var cb = null;
        if (typeof done === "function") {
            cb = this.newAsync(events.length, done);
        } else {
            cb = function () {};
        }

        for (var i in events) {
            if (events.hasOwnProperty(i)) {
                events[i](this, this.pageView(page), page, cb);
            }
        }
    }

    pageView(page) {
        if (typeof page !== "number") {
            if (typeof page == "string" && page.length > 0) {
                page = this.slugs.indexOf(page);
            } else {
                page = this.page;
            }
        }
        return this.pages[page].view;
    }

    listPages() {
        return this.pages;
    }

    openSlug(slug) {
        this.open(this.slugs.indexOf(slug));
    }

    open(new_page, cb) {
        if (this.page !== new_page) {
            var old = this.page;
            this.page = new_page;
            if (typeof old == "number") {
                var _this = this;
                this.triggerPageEvents("close", old, function () {
                    _this.triggerPageEvents("open");
                });
            } else {
                this.triggerPageEvents("open", null, cb);
            }
        } else {
            cb();
        }
    }

    close(cb) {
        if (typeof this.page == "number") {
            this.triggerPageEvents("close", null, cb);
            this.page = null;
        } else {
            cb();
        }
    }

    next() {
        if (typeof this.page != "number") {
            // no page open
            this.open(0);
        } else if (this.page + 1 == this.pages.length) {
            // last page
            this.close();
        } else {
            this.open(this.page + 1);
        }
    }
}
