window.initMap = function () {
    var args = {
        center: { lat: 61.751, lng: 14.631 },
        scrollwheel: true,
        zoom: 5,
    };

    var map = new window.google.maps.Map(document.getElementById("map"), args);

    map.set("styles", [
        {
            featureType: "administrative",
            elementType: "labels.text.fill",
            stylers: [
                {
                    color: "#444444",
                },
            ],
        },
        {
            featureType: "landscape",
            elementType: "all",
            stylers: [
                {
                    color: "#773178",
                },
                {
                    lightness: "90",
                },
            ],
        },
        {
            featureType: "landscape.natural",
            elementType: "all",
            stylers: [
                {
                    color: "#773178",
                },
                {
                    saturation: "0",
                },
                {
                    lightness: "90",
                },
            ],
        },
        {
            featureType: "poi",
            elementType: "all",
            stylers: [
                {
                    visibility: "off",
                },
            ],
        },
        {
            featureType: "road",
            elementType: "all",
            stylers: [
                {
                    saturation: -100,
                },
                {
                    lightness: 45,
                },
            ],
        },
        {
            featureType: "road.highway",
            elementType: "all",
            stylers: [
                {
                    visibility: "simplified",
                },
            ],
        },
        {
            featureType: "road.arterial",
            elementType: "labels.icon",
            stylers: [
                {
                    visibility: "off",
                },
            ],
        },
        {
            featureType: "transit",
            elementType: "all",
            stylers: [
                {
                    visibility: "off",
                },
            ],
        },
        {
            featureType: "water",
            elementType: "all",
            stylers: [
                {
                    color: "#773178",
                },
                {
                    visibility: "on",
                },
            ],
        },
    ]);

    $.getJSON("/wp-json/clockwork/v1/offices", function (data) {
        $.each(data, function (index, value) {
            // console.log(value);

            // Ugly fix. But will do for now.
            if (value.slug == "skolbemanning" || value.slug == "global") {
                return;
            }

            var marker = new window.google.maps.Marker({
                position: {
                    lat: parseFloat(value.lat),
                    lng: parseFloat(value.lng),
                },
                map: map,
                title: value.name,
                url: "/kontakta-oss/" + value.slug,
                icon: THEME_DIR + "/img/map-pointer.svg",
            });

            window.google.maps.event.addListener(marker, "click", function () {
                window.location.href = this.url;
            });
        });
    }).fail(function () {
        console.log("Error :'/wp-json/clockwork/v1/offices'");
    });
};
